import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  i18nFlightConfig,
  enFlightTranslation,
  heFlightTranslation,
} from '@bepro-travel/fe.flight';
import { i18nHotelConfig, enHotelTranslation, heHotelTranslation } from '@bepro-travel/fe.hotel';
import {
  i18nTransferConfig,
  enTransferTranslation,
  heTransferTranslation,
} from '@bepro-travel/fe.transfer';
import {
  i18nPackageConfig,
  enPackageTranslation,
  hePackageTranslation,
} from '@bepro-travel/fe.package';
import {
  i18nDynConfig,
  enDynamicTranslation,
  heDynamicTranslation,
} from '@bepro-travel/fe.dynamic';
import {
  i18nSharedConfig,
  enSharedTranslation,
  heSharedTranslation,
} from '@bepro-travel/fe.shared';
import { i18nConfig } from '@bepro-travel/fe.ui';
import { format } from 'date-fns';
import { enUS, he } from 'date-fns/locale';
import { heTr } from '../locales/he';
import { enTr } from '../locales/en';

export const dateFormat = (date: Date | string | number, formatStr: string) => {
  const lang = localStorage.getItem('language');

  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, formatStr, { locale: lang === 'he' ? he : enUS });
};

export const i18nconfig = () => {
  localStorage.setItem('language', 'he');
  const lang = localStorage.getItem('language');

  const enTranslation = {
    ...enFlightTranslation,
    ...enHotelTranslation,
    ...enTransferTranslation,
    ...enPackageTranslation,
    ...enDynamicTranslation,
    ...enSharedTranslation,
    ...enTr,
    package: { ...enPackageTranslation.package, ...enTr.package },
  };

  const heTranslation = {
    ...heFlightTranslation,
    ...heHotelTranslation,
    ...heTransferTranslation,
    ...hePackageTranslation,
    ...heDynamicTranslation,
    ...heSharedTranslation,
    ...heTr,
    package: { ...hePackageTranslation.package, ...heTr.package },
  };

  i18nFlightConfig({ enTranslation, heTranslation });
  i18nHotelConfig({ enTranslation, heTranslation });
  i18nTransferConfig({ enTranslation, heTranslation });
  i18nPackageConfig({ enTranslation, heTranslation });
  i18nConfig({ enTranslation, heTranslation });
  i18nDynConfig({ enTranslation, heTranslation });
  i18nSharedConfig({ enTranslation, heTranslation, ruTranslation: {} });

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      he: {
        translation: heTranslation,
      },
    },
    lng: lang ? lang : 'he',
    fallbackLng: 'he',

    interpolation: {
      escapeValue: false,
    },
  });
};

export default i18n;
